<template>
  <b-card>
    <form-component :file="file" :lang="lang" @onSubmit="onSubmit" @fetchByLocale="fetchFile" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/downloadable-files/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, update } from '@/http/downloadable-files';
import { convertFromURLToFile } from '@/constants/utils/file';

import { PRIORITY_LEVELS } from '@/constants/enums/priority';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      lang: null,
      file: {
        name: '',
        tag: null,
        file: null,
        url: '',
        is_public: false,
        priority: PRIORITY_LEVELS.CRITICAL,
      },
    };
  },

  async mounted() {
    await this.fetchFile();
  },

  methods: {
    async fetchFile(lang = 'pl') {
      try {
        const { data } = await show(this.$route.params.id, { lang });

        data.data.file = await convertFromURLToFile(data.data.url);

        this.file = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Wystąpił błąd podczas pobierania pliku. Skontaktuj się ze swoim developerem.',
        );
      }
    },
    async onSubmit(form) {
      const formData = new FormData();

      formData.append('lang', form.lang);
      formData.append('name', form.name);
      formData.append('file', form.file);
      formData.append('tag_id', form.tag.id);
      formData.append('priority', form.priority);
      formData.append('is_public', form.is_public ? 1 : 0);

      try {
        await update(this.file.id, formData);

        this.showSuccessNotification('Dane zapisane', 'Plik został zaktualizowany.');
        this.$router.push({ name: 'downloadable-files-index' });
      } catch (error) {
        const message =
          error.response.data.message ?? 'Wystąpił błąd podczas edycji pliku. Skontaktuj się ze swoim developerem.';
        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
